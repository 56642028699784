<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="qcm-package-preview">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<div class="package-container">
				<p class="title">{{ content.title }}</p>

				<div v-for="question in clonedContent.questions_data" :key="question.id">
					<p class="question" v-html="question.question"></p>
					<div v-if="question.displayedImg" class="img-container">
						<img width="200" :src="question.displayedImg" alt="">
					</div>
					<div class="answer-container">
						<p v-for="(answer) in question.answers" :key="answer.id">
							<span :class="answer.is_correct ? 'correct' : ''">
								<input type="checkbox" :checked="answer.is_correct">  <span v-html="answer.answer"> </span>
							</span>
						</p>
					</div>

				</div>
			</div>
			<SelectStatus v-if="checkRouteAdmin()" :id="content.id" @close="$emit('close')" @updateQuestion="$emit('updateQcmPackage', content.id)" :resource="'qcm-question-packages'" />
		</div>
	</pop-up-wrapper>
</template>

<script>
import { getQCMQuestionPackageService } from '../../../services/qcmQuestionPackagesServices'
import { authenticatedImageFetch } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
import SelectStatus from '../QCM/SelectStatus.vue'
export default {
	components: { PopUpWrapper, SelectStatus },
	emits: ['close'],
	props: {
		content: Object,
	},
	data() {
		return {
			clonedContent: {}
		}
	},
	

	methods: {
		checkRouteAdmin() {
			return this.$route.path.includes('/super-admin/')
		},
		async getImg(question){
			const res = await authenticatedImageFetch(question.image)
				.then(res => res.blob())

			return URL.createObjectURL(res)
		},
		async getQCMPackageWithQuestions(){
			const res = await getQCMQuestionPackageService(this.content.id)
			this.clonedContent.questions_data = res.questions_data
		}
	},
	async created() {
		this.clonedContent = JSON.parse(JSON.stringify(this.content))
		await this.getQCMPackageWithQuestions()
		for await (const question of this.clonedContent.questions_data) {
			if (question.image) {
				question.displayedImg = await this.getImg(question)
			}
		}
	},
	

}
</script>

<style>
</style>
