<template>
	<div class="select-status-container">
		<button class="submit approve" v-if="checkUrl('validate')" @click="updateStatus('validate')">Approuver</button>
		<button class="submit refuse" v-if="checkUrl('refuse')" @click="updateStatus('refuse')">Refuser</button>
		<button class="submit pending" v-if="checkUrl('pending')" @click="updateStatus('pending')">A valider</button>
	</div>
</template>
<script>
import { authenticatedApiFetch } from '../../../utils'

export default {
	props: {
		id: Number,
		resource: {
			type: String,
			default: 'qcm-questions'
		}
	},

	methods:{
		checkUrl(action){
			switch(action){
				case 'validate':
					return !this.$route.path.includes('/super-admin/all-qcms')
				case 'pending':
					return !this.$route.path.includes('/super-admin/pending-qcms')
			}
			return this.$route.path.includes('/super-admin/')
		},
		async updateStatus(status){
			if(!confirm('Voulez-vous vraiment changer le statut de cette question ?')) return
			await authenticatedApiFetch(`/${this.resource}/${this.id}/${status}/`, {
				method: 'GET',
			}) 
			this.$store.state.toast = {
				status: 'success',
				text: 'Statut mis à jour'
			}
			
			this.$emit('updateQuestion')
			this.$emit('close')
		}	
	}
}

</script>
<style scoped lang="scss">
.select-status-container{
	display: flex;
	justify-content: center;
	margin-inline: auto;
	gap: 2rem;
	margin-top: 4rem;

	.refuse{
		background: #C22A29;
		color: white;
	}

	.pending{
		background: rgb(240, 150, 26);
		color: white;
	}

	.approve{
		background: #3732AF;
		color: white;
	}
}
</style>
